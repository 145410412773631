<template>
  <div>
    <div class="container">
      <el-container>
        <transition name="el-zoom-in-center">
          <i
            v-show="!showTree"
            class="el-icon-s-unfold"
            @click="showTree=true"
            style="float:left;cursor: pointer;color:#333;font-size: 24px;margin-right: 16px;"
          ></i>
        </transition>
        <transition name="el-zoom-in-top">
          <el-aside width="240px" v-show="showTree">
            <i
              class="el-icon-s-fold"
              @click="showTree=false"
              style="float:right;cursor: pointer;color:#333;font-size: 30px;margin-right: 8px;"
            ></i>
            <type-tree typeKind="SupplierType" @currentTypeArray="currentTypeArray"></type-tree>
          </el-aside>
        </transition>

        <el-main>
          <el-form :inline="true" :model="filter" size="mini">
            <el-form-item label="供应商">
              <el-input v-model="filter.supplier" placeholder="名称、编号、拼音" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="filter.linkMan" placeholder="联系人" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="filter.linkTel" placeholder="联系电话" clearable></el-input>
            </el-form-item>
            <el-form-item label="显示停用">
              <el-switch v-model="filter.showAll"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-plus" @click="addSupplier" type="primary">添加</el-button>
              <el-button type="success" @click="excel">
                <span class="iconfont iconexcel" style="font-size:12px;"></span>
                导出
              </el-button>
            </el-form-item>
          </el-form>
          <el-table
            v-loading="loading"
            :data="data"
            stripe
            border
            :row-class-name="tableRowClassName"
            @row-dblclick="editSupplier"
          >
            <el-table-column label="供应商编号" prop="supplierCode" width="90" show-overflow-tooltip></el-table-column>
            <el-table-column label="供应商名称" prop="supplierName" show-overflow-tooltip></el-table-column>
            <el-table-column label="应付/预付" prop="balance" show-overflow-tooltip>
              <template
                slot-scope="scope"
              >{{scope.row.balance|thousands(2)}}/{{scope.row.repayAmount|thousands(2)}}</template>
            </el-table-column>
            <el-table-column label="联系人" prop="linkMan" show-overflow-tooltip></el-table-column>
            <el-table-column label="联系电话" prop="linkTel" show-overflow-tooltip></el-table-column>
            <el-table-column label="供应商类别" prop="typeName" show-overflow-tooltip></el-table-column>
            <el-table-column label="地址" prop="addres" show-overflow-tooltip></el-table-column>
            <el-table-column label="银行账号" prop="bankNo" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="65">
              <template slot-scope="scope">
                <el-tag type="danger" effect="dark" @click="removeItem(scope.row)">删除</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 50, 100, 500]"
            @size-change="getData(1)"
            @current-change="getData()"
            :current-page.sync="pageIndex"
            :page-size.sync="pageSize"
            :total="pageTotal"
          ></el-pagination>
        </el-main>
      </el-container>
    </div>

    <div>
      <add-edit :item="item" @success="getData()"></add-edit>
    </div>
  </div>
</template>

<script>
import TypeTree from "@/components/BaseType/TypeTree";
import AddEdit from "@/views/supplier/Item";
import setName from "@/common/setName";
import toExcel from "@/common/toExcel";
export default {
  components: {
    AddEdit,
    TypeTree
  },
  data() {
    return {
      showTree: this.$store.state.baseinfo.SupplierType.length > 0,
      filter: {
        typeArray: []
      },
      loading: false,
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      item: {}
    };
  },
  watch: {
    filter: {
      handler: function(v) {
        this.getData(1);
      },
      deep: true
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.isStop) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getData(pageIndex) {
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      this.loading = true;
      let params = {
        ...this.filter,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      this.$get("Supplier/GetAllSupplier", params)
        .then(r => {
          let data = r;
          data.list.forEach(item => {
            setName("SupplierType", item, "typeId", "typeName");
          });
          this.data = data.list;
          this.pageTotal = data.pageTotal;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addSupplier() {
      if (this.filter.typeArray.length == 0) {
        this.item = {};
      } else {
        this.item = {
          typeId: this.filter.typeArray[0]
        };
      }
    },
    editSupplier(row) {
      this.item = {
        id: row.id
      };
    },
    removeItem(row) {
      this.$confirm("将删除【" + row.supplierName + "】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("Supplier/DeleteSupplier?id=" + row.id).then(r => {
            this.$message.success("操作成功！");
            this.$store.commit("removeBaseInfo", {
              info: "Supplier",
              id: row.id
            });
            this.getData();
          });
        })
        .catch(() => {});
    },
    currentTypeArray(array) {
      this.filter.typeArray = array;
    },
    excel() {
      let loading = this.$loading({
        lock: true,
        text: "请求数据中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let params = {
        ...this.filter,
        pageIndex: 1,
        pageSize: this.pageTotal
      };
      this.$get("Supplier/GetAllSupplier", params)
        .then(r => {
          r.list.forEach(item => {
            setName("SupplierType", item, "typeId", "typeName");
          });
          let list = r.list;
          let header = [
            "供应商编号",
            "供应商名称",
            "应付",
            "预付",
            "联系人",
            "联系电话",
            "供应商类别",
            "地址",
            "银行账号",
            "备注"
          ];
          let column = [
            "supplierCode",
            "supplierName",
            "balance",
            "repayAmount",
            "linkMan",
            "linkTel",
            "typeName",
            "addres",
            "bankNo",
            "remark"
          ];

          toExcel(header, column, list, "供应商资料");
        })
        .finally(() => {
          loading.close();
        });
    }
  }
};
</script>

<style>
</style>